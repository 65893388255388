// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'

import "./src/styles/style.css"





let prevloc = ""


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  

  if (prevloc === location.pathname) {
    return false;
  }

	prevloc = location.pathname

  // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
  if (location.search.includes("video=")) {
    return false;
  }



  const currentPosition = getSavedScrollPosition(location);
  return currentPosition || [0, 0];
};
